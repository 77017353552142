import React from "react"
import propTypes from "prop-types"
import CookiesBanner from "../CookiesBanner/CookiesBanner"
import "@fontsource/ruda"

import "../../sass/style.scss"

const Layout = ({ children, classes }) => {
  return (
    <>
      <main id="main" className={classes ?? classes}>
        {children}
      </main>
      <CookiesBanner />
    </>
  )
}

Layout.defaultProps = {
  classes: null,
  children: null,
}

Layout.propTypes = {
  classes: propTypes.string,
  children: propTypes.node,
}

export default Layout
