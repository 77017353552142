import React from "react"
import parse from "html-react-parser"
import PropTypes from "prop-types"
import EnsLink from "../EnsLink/EnsLink"
import PreviousIcon from "../../assets/images/png/previous.png"
import "./VideoPostSingle.scss"
import "@wordpress/block-library/build-style/style.css"
import { useTranslation } from "react-i18next"

const VideoPostSingle = ({ video }) => {
  const { t } = useTranslation();
  const pageLocations = {
    videos: {
      link: "/librarie-video-ensys",
      label: "librariile",
    },
  }

  let currentPageLocation = pageLocations.videos

  return (
    <div className="container">
      <article
        itemScope
        itemType="http://schema.org/Article"
        className="video-single"
      >
        <div className="parent-header">
          <div className="parent-header-left">
            <div className="back-button">
              <EnsLink
                link={{
                  url: currentPageLocation.link,
                }}
              >
                <img src={PreviousIcon} alt="ensys Video" />
                {t("Înapoi la videoclipuri")}
              </EnsLink>
            </div>

            <div className="row">
              <div className={"col-md-12"}>
                {video.title && (
                  <h2 className="article-title">{parse(video.title)}</h2>
                )}
                {video.date && <span className="article-date"> {video.date}</span>}
              </div>
            </div>
          </div>
          {video.postExtraData?.dataAboutPost?.url && (
            <div className="parent-header-right">
              <div className="iframe-container">
                <iframe src={video.postExtraData?.dataAboutPost?.url} frameborder="0" allowfullscreen></iframe>
              </div>
            </div>
          )}
        </div>

        {!!video.content && (
          <section
            itemProp="articleBody"
            className="article-content"
            dangerouslySetInnerHTML={{ __html: video.content }}
          />
        )}
      </article>
    </div>
  )
}

VideoPostSingle.propTypes = {
  video: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
    date: PropTypes.string,
  }).isRequired,
}

export default VideoPostSingle
