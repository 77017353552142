/* eslint-disable react/prop-types */
import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout/Layout"
import Header from "../components/Header/Header"
import VideoPostSingle from "../components/VideoPostSingle/VideoPostSingle"
import Footer from "../components/Footer/Footer"
import { Helmet } from "react-helmet"

const VideoPostTemplate = ({ data: { post } }) => {
  return (
    <Layout>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Header />
      <VideoPostSingle video={post} />
      <Footer />
    </Layout>
  )
}

export default VideoPostTemplate

export const pageQuery = graphql`
  query VideoPostById($id: String!) {
    post: wpVideo(id: { eq: $id }) {
      id
      title
      content
      date(formatString: "MMMM DD, YYYY")
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        opengraphImage {
          link
        }
      }
      postExtraData {
        dataAboutPost {
          url
        }
      }
    }
  }
`
